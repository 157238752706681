import Index from './../views/clientes/Index.vue';
import Form from './../views/clientes/Form.vue';
const routes = [
	{
		path: '/Clientes',
		name: 'clientes',
		component: Index,
		meta: {
			name: 'Catálogo de Clientes'
		}
	},
	{
		path: '/Clientes/nuevo',
		name: 'clientes.create',
		component: Form,
		meta: {
			name: 'Nuevo Cliente'
		}
	},
	{
		path: '/Clientes/editar/:id',
		name: 'clientes.edit',
		component: Form,
		meta: {
			name: 'Editando Cliente'
		}
	}
];
export default routes;
