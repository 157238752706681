try {
	window._ = require('lodash');
	window.Popper = require('popper.js').default;
	window.axios = require('axios');
	window.swal = require('sweetalert');
	window.moment=require('moment');
	// eslint-disable-next-line no-empty
} catch (e) {}
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

if (localStorage.token) {
	window.axios.defaults.headers.common.Authorization =
		'Bearer ' + localStorage.token;
}
