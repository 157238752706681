import Salir from './../views/logout.vue';
import EditMe from './../views/editMe.vue';
import Home from '../views/Home.vue';
const routes = [
	{
		path: '/Dashboard',
		name: 'Home',
		component: Home,
		meta: {
			name: 'Dashboard'
		}
	},
	{
		path: '/salir',
		name: 'logout',
		component: Salir,
		meta: {
			name: 'Saliendo del Sistema....'
		}
	},
	{
		path: '/Editar-mis-datos',
		name: 'editMe',
		component: EditMe,
		meta: {
			name: 'Editar Mis Datos'
		}
	}
];
export default routes;
