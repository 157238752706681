<template>
	<v-card height="100%" v-if="user.id">
		<v-card-text> Bienvenido {{ user.nombre }} </v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Daschoard',
	computed: {
		...mapState({
			user: state => state.Auth.user
		})
	}
};
</script>

