/* eslint-disable vue/valid-v-for */
<template>
	<div>
		<v-navigation-drawer v-model="drawer" app>
			<div class="text-center py-5">
				<img :src="require('./logo.png')" width="40%" alt="" />
			</div>

			<v-list dense shaped>
				<v-list-item-group color="primary">
					<v-list-group
						:value="false"
						prepend-icon="mdi-folder"
						v-if="catalogos.length"
					>
						<template v-slot:activator>
							<v-list-item-title>Catálogos</v-list-item-title>
						</template>
						<v-list-item
							link
							:to="{ name: item.to }"
							v-for="item in catalogos"
							:key="item.title"
						>
							<v-list-item-action>
								<v-icon v-text="item.icon"></v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>{{ item.title }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>
					<v-list-item
						link
						:to="{ name: item.to }"
						v-for="item in menu"
						:key="item.title"
						:class="item.class"
					>
						<v-list-item-action>
							<v-icon v-text="item.icon"></v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<v-app-bar color="primary" dark app>
			<v-app-bar-nav-icon @click="drawer = !drawer" />
			<v-toolbar-title>{{ title }}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn
				v-for="item in barra"
				:key="item.title"
				icon
				link
				:to="{ name: item.to }"
				class="d-none d-xl-flex"
			>
				<v-icon>{{ item.icon }}</v-icon>
			</v-btn>
		</v-app-bar>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Menu',
	data: () => ({
		drawer: null
	}),
	computed: {
		...mapState({
			menu: state => state.Auth.menu,
			catalogos: state => state.Auth.catalogos
		}),
		title() {
			return this.$route.meta.name ? this.$route.meta.name : '';
		},
		barra() {
			if (!this.menu) return [];
			return this.menu.filter(x => x.class);
		}
	},
	mounted() {
		this.$store.dispatch('Auth/getMenu');
	}
};
</script>
