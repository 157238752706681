<template>
  <div />
</template>
<script>
import { mapActions } from 'vuex';
export default {
	mounted() {
		this.logout();
	},
	methods: {
		...mapActions('Auth', ['logout'])
	}
};
</script>