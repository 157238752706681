require('./bootstrap');
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
loadProgressBar();
import './sass/style.scss';
import './registerServiceWorker';

Vue.config.productionTip = false;
Vue.filter('date', function(value) {
	if (value)
		return window
			.moment(value)
			.locale('es')
			.format('DD/MMM/YYYY');
	return '';
});
Vue.filter('dateTime', function(value) {
	if (value)
		return window
			.moment(value)
			.locale('es')
			.format('DD/MMM/YYYY hh:mm A');
	return '';
});
Vue.filter('moneda', function(value) {
	if (!value) return '';
	if (isNaN(value)) value = parseFloat(value);
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});
	if (isNaN(value)) return '';
	var numberString = formatter
		.formatToParts(value)
		.map(({ type, value }) => {
			switch (type) {
				case 'currency':
					return `${value} `;
				default:
					return value;
			}
		})
		.reduce((string, part) => string + part);
	return numberString;
});
Vue.filter('folio', function(value) {
	return String(value).padStart(5, '0');
});
Vue.filter('numero', function(value) {
	if (isNaN(value)) value = parseFloat(value);
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});
	if (isNaN(value)) return '';
	var numberString = formatter
		.formatToParts(value)
		.map(({ type, value }) => {
			switch (type) {
				case 'currency':
					return ` `;
				case 'fraction':
					return '';
				case 'decimal':
					return '';
				default:
					return value;
			}
		})
		.reduce((string, part) => string + part);
	return numberString;
});

Vue.mixin({
	methods: {
		userCan(ability) {
			let abilities = store.state.Auth.user.abilities;
			let encontrado = abilities.find(x => {
				return x.name === '*' || x.name === ability;
			});
			if (encontrado) return true;
		},
		row_classes(item) {
			if (item.deleted_at || item.trashed) {
				return 'red lighten-5'; //can also return multiple classes e.g ["orange","disabled"]
			}
		}
	}
});

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app');
