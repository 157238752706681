<template>
	<v-card height="100%"> </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Show',
	beforeMount() {
		if (!this.resource) {
			let id = this.$route.params.id;
			this.$store.dispatch('Budgets/getResource', id);
		}
	},
	mounted() {},
	computed: {
		...mapState({
			resource: state => state.Budgets.resource
		})
	}
};
</script>
