<template>
	<v-card height="100%">
		<v-form @submit.prevent="$store.dispatch('Budgets/sendResource')">
			<v-card-text v-if="clientes">
				<v-row>
					<v-col cols="12" xl="4">
						<v-autocomplete
							clearable
							small-chips
							dense
							outlined
							v-model="cliente_id"
							label="Cliente"
							:items="clientes"
							:error-messages="errors.cliente_id"
						/>
					</v-col>
					<v-col cols="12" xl="4">
						<v-combobox
							dense
							outlined
							v-model="budget.comprador"
							:items="buyers"
							label="Comprador"
              :error-messages="errors.comprador"
						/>
					</v-col>
					<v-col cols="12" xl="4">
						<v-select
							outlined
							clearable
							small-chips
							dense
							v-model="budget.customer_address_id"
							label="Domicilio"
							:items="domicilios2"
							:error-messages="errors.customer_address_id"
						/>
					</v-col>
					<v-col cols="12" xl="4">
						<v-select
							outlined
							clearable
							small-chips
							dense
							v-model="budget.warehouse_address_id"
							label="Warehouse"
							:items="warehouses"
							:error-messages="errors.warehouse_address_id"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn color="primary" type="submit" :disabled="loader">
					guardar
				</v-btn>
				<v-btn
					color="error"
					@click="$router.push({ name: 'budgets' })"
					:disabled="loader"
				>
					cancelar
				</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Create',
	beforeMount() {
		if (!this.clientes) this.$store.dispatch('Budgets/getAll');
		if (!this.resource) this.$store.commit('Budgets/setResource', {});
	},
	mounted() {
		this.$store.commit('setErrors');
		if (!this.clientes) this.$store.dispatch('Budgets/getAll');
	},
	data: () => ({
		cliente_id: undefined
	}),
	computed: {
		...mapState({
			errors: state => state.errors,
			clientes: state => state.Budgets.clientes,
			warehouses: state => state.Budgets.warehouses,
			domicilios: state => state.Budgets.domicilios,
			loader: state => state.Budgets.loader,
			compradores: state => state.Budgets.compradores,
			budget: state => state.Budgets.resource
		}),
		domicilios2() {
			if (!this.domicilios) return [];
			return this.domicilios.filter(x => x.cliente_id == this.cliente_id);
		},
		buyers() {
			if (!this.domicilios) return [];
			let compradores = this.compradores.filter(
				x => x.cliente_id == this.cliente_id
			);
			return [...new Set(compradores.map(x => x.comprador))].sort();
		}
	},
	watch: {
		cliente_id: function(val) {
			this.budget.cliente_id = val;
			this.budget.comprador = undefined;
		}
	}
};
</script>
