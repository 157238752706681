<template>
	<div>
		<v-footer color="primary" class="font-weight-medium" dark app>
			<v-col class="text-rigth" cols="12">
				{{ appName }} &copy; {{ new Date().getFullYear() }}
			</v-col>
		</v-footer>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Footer',
	computed: {
		...mapState({
			appName: state => state.appName
		})
	}
};
</script>
