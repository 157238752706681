<template>
	<v-card height="100%">
		<v-card-text>
			<v-form @submit.prevent="$store.dispatch('Budgets/getAll')">
				<v-row>
					<v-col cols="12" xl="4">
						<v-autocomplete
							dense
							outlined
							clearable
							label="Filtrar por cliente"
							:items="clientes"
							v-model="paginado.cliente_id"
						/>
					</v-col>
					<v-col cols="12" xl="4">
						<v-text-field
							dense
							outlined
							clearable
							label="Buscar por PU o PO"
							v-model="paginado.search"
						/>
					</v-col>
					<v-col cols="12" xl="4">
						<v-btn color="primary" type="submit">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
						<v-btn
							color="success"
							class="ml-3"
							v-if="userCan('make-budget')"
							@click="$store.dispatch('Budgets/nuevo')"
						>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
			<v-data-table
				dense
				:items="items"
				:headers="headers"
				class="elevation-1 mt-3"
				mobile-breakpoint="0"
				hide-default-footer
				:items-per-page="paginado.perPage"
			>
				<template v-slot:[`item.id`]="{ item }">
					{{ item.id | folio }}
				</template>
				<template v-slot:[`item.fecha`]="{ item }">
					{{ item.fecha | date }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.finalizado`]="{ item }">
					{{ item.finalizado ? 'SI' : '' }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn
						icon
						color="success"
						v-if="!item.finalizado && item.actions.edit"
            @click="$store.dispatch('Budgets/getResource',item.id)"
					>
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-btn icon color="red" v-if="item.cancelable && item.actions.delete">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
					<template v-else>
						<v-btn
							icon
							color="primary"
							:href="item.shipper"
							target="_blank"
							v-if="item.actions.shipper"
						>
							<v-icon>mdi-file-pdf</v-icon>
						</v-btn>
						<v-btn
							icon
							color="red"
							:href="item.pdfPU"
							target="_blank"
							v-if="item.actions.pu"
						>
							<v-icon>mdi-file-pdf</v-icon>
						</v-btn>
					</template>
				</template>
			</v-data-table>
			<v-row v-if="!loader && items && items.length">
				<v-col class="mt-5">
					Registros Encontrados: {{ paginado.registros | numero }}
				</v-col>
				<v-col cols="12" md="8">
					<v-pagination
						class="mt-3"
						v-model="paginado.currentPage"
						:length="paginado.lastPage"
						total-visible="8"
					/>
				</v-col>
				<v-col></v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Budgets',
	mounted() {
		this.$store.dispatch('Budgets/getAll');
    
	},
	computed: {
		...mapState({
			loader: state => state.Budgets.loader,
			headers: state => state.Budgets.headers,
			items: state => state.Budgets.collection,
			paginado: state => state.Budgets.paginado,
			clientes: state => state.Budgets.clientes
		}),
		actual() {
			return this.paginado.currentPage;
		}
	},
	watch: {
		actual: function() {
			this.$store.dispatch('Budgets/getAll');
		}
	}
};
</script>
