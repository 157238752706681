import Index from './../views/budgets/Index.vue';
import Create from './../views/budgets/Create.vue';
import Show from './../views/budgets/Show.vue';
const routes = [
	{
		path: '/Presupuestos',
		name: 'budgets',
		component: Index,
		meta: {
			name: 'Control de Presupuestos'
		}
	},
	{
		path: '/Presupuestos/nuevo',
		name: 'budgets.create',
		component: Create,
		meta: {
			name: 'Nuevo Presupuesto'
		}
	},
	{
		path: '/Presupuestos/detalles/:id',
		name: 'budgets.show',
		component: Show,
		meta: {
			name: 'Detalles Presupuesto'
		}
	}
];
export default routes;
