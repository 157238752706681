import router from './../../router';
const initialState = () => ({
	loader: false,
	headers: [],
	paginado: { currentPage: 1, search: null },
	collection: undefined,
	resource: undefined
});
const url = 'ventasfr/clientes';
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},
		setLoader(state, payload = true) {
			state.loader = payload;
		},
		setHeaders(state, payload) {
			state.headers = payload;
		},
		setPaginado(state, payload) {
			state.paginado = payload;
		},
		setCollection(state, payload = []) {
			state.collection = payload;
		},
		setResource(state, payload) {
			state.resource = payload;
		}
	},
	actions: {
		async getAll({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setCollection');
			window.axios
				.get(url, {
					params: {
						page: state.paginado.currentPage,
						search: state.paginado.search
					}
				})
				.then(resp => {
					commit('setCollection', resp.data.collection);
					commit('setHeaders', resp.data.headers);
					let paginado = resp.data.paginado;
					if (paginado.currentPage > paginado.lastPage)
						paginado.currentPage = 1;
					commit('setPaginado', paginado);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		nuevo({ commit }) {
			commit('setResource', {});
			router.push({ name: 'clientes.create' }).catch(() => {});
		},
		async sendResource({ commit, dispatch, state }) {
			let cliente = state.resource;
			commit('setLoader');
			commit('setErrors', [], { root: true });
			if (!cliente.id) {
				await window.axios
					.post(url, cliente)
					.then(resp => {
						window.swal(resp.data.message, { icon: 'success', timer: 1500 });
						router.push({ name: 'clientes' }).catch(() => {});
					})
					.catch(e => dispatch('errores', e, { root: true }))
					.finally(() => commit('setLoader', false));
			} else {
				await window.axios
					.put(`${url}/${cliente.id}`, cliente)
					.then(resp => {
						window.swal(resp.data.message, { icon: 'success', timer: 1500 });
						router.push({ name: 'clientes' }).catch(() => {});
					})
					.catch(e => dispatch('errores', e, { root: true }))
					.finally(() => commit('setLoader', false));
			}
		},
		async getResource({ commit, dispatch }, id) {
			commit('setLoader');
			commit('setErrors', [], { root: true });
			await window.axios
				.get(`${url}/${id}`)
				.then(resp => {
					commit('setResource', resp.data);
					router.push({ name: 'clientes.edit' }).catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async deleteResource({ commit, dispatch }, id) {
			commit('setLoader');
			commit('setErrors', [], { root: true });
			await window.axios
				.delete(`${url}/${id}`)
				.then(resp => {
					dispatch('getAll');
					window.swal(resp.data.message, { icon: 'success', timer: 1500 });
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		}
	}
};
