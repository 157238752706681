import router from './../../router';
const initialState = () => ({
	loader: false,
	headers: [],
	paginado: { currentPage: 1, search: null },
	collection: undefined,
	resource: undefined,
	clientes: undefined,
	compradores: undefined,
	domicilios: undefined
});
const url = 'FR/budgets';
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},
		setLoader(state, payload = true) {
			state.loader = payload;
		},
		setHeaders(state, payload) {
			state.headers = payload;
		},
		setPaginado(state, payload) {
			state.paginado = payload;
		},
		setCollection(state, payload = []) {
			state.collection = payload;
		},
		setResource(state, payload) {
			state.resource = payload;
		},
		setClientes(state, payload) {
			state.clientes = payload;
		},
		setCompradores(state, payload) {
			state.compradores = payload;
		},
		setDomicilios(state, payload) {
			state.domicilios = payload;
		},
		setWarehouses(state, payload) {
			state.warehouses = payload;
		}
	},
	actions: {
		async getAll({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setCollection');
			window.axios
				.get(url, {
					params: {
						page: state.paginado.currentPage,
						search: state.paginado.search,
						cliente_id: state.paginado.cliente_id
					}
				})
				.then(resp => {
					commit('setCollection', resp.data.collection);
					commit('setHeaders', resp.data.headers);
					let paginado = resp.data.paginado;
					if (paginado.currentPage > paginado.lastPage)
						paginado.currentPage = 1;
					commit('setPaginado', paginado);
					commit('setClientes', resp.data.clientes);
					commit('setCompradores', resp.data.compradores);
					commit('setDomicilios', resp.data.domicilios);
					commit('setWarehouses', resp.data.almacenes);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		nuevo({ commit }) {
			commit('setResource', {});
			router.push({ name: 'budgets.create' });
		},
		async sendResource({ commit, dispatch, state }) {
			let resource = state.resource;
			commit('setLoader');
			commit('setErrors', [], { root: true });
			if (!resource.id) {
				window.axios
					.post(url, resource)
					.then(resp => {
						window.swal(resp.data.message, { icon: 'success', timer: 1500 });
						dispatch('getResource', resp.data.id);
					})
					.catch(e => dispatch('errores', e, { root: true }))
					.finally(() => commit('setLoader', false));
			}
		},
		async getResource({ commit, dispatch }, id) {
			commit('setLoader');
			commit('setErrors', [], { root: true });
			await window.axios
				.get(`${url}/${id}`)
				.then(resp => {
					commit('setResource', resp.data);
					router.push({ name: 'budgets.show', params: { id } }).catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async deleteResource({ commit, dispatch }, id) {}
	}
};
