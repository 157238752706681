import Index from './../views/abilities/Index.vue';
import store from './../store';
const user = store.state.Auth.user;
const routes = [
	{
		path: '/Abilities',
		name: 'abilities',
		component: Index,
		meta: {
			name: 'Catálogo de Permisos'
		}
	}
];

export default routes;
