<template>
	<div v-if="login">
		<v-app id="inspire">
			<Menu />
			<v-main>
				<router-view />
			</v-main>
			<Footer />
		</v-app>
		
	</div>
	<div v-else>
		<Login />
	</div>
</template>

<script>
import Menu from './components/menu';
import Login from './views/login';
import Footer from './components/footer.vue';
import { mapState, mapActions } from 'vuex';
export default {
	name: 'App',
	components: { Menu, Login, Footer },
	computed: {
		...mapState({
			login: state => state.Auth.login
		})
	},
	beforeMount() {
		this.checkUser();
	},
	methods: {
		...mapActions('Auth', ['checkUser'])
	}
};
</script>
