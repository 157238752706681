<template>
	<v-card height="100%">
		<v-card-text>
			<v-form @submit.prevent="$store.dispatch('Clientes/getAll')">
				<v-row>
					<v-col cols="12" md="6">
						<v-text-field
							dense
							outlined
							clearable
							label="Buscar"
							v-model="paginado.search"
						/>
					</v-col>
					<v-col>
						<v-btn color="primary" type="submit">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
						<v-btn
							color="success"
							class="ml-3"
							v-if="userCan('fr-crear-clientes')"
							@click="$store.dispatch('Clientes/nuevo')"
						>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
			<v-data-table
				dense
				:headers="headers"
				:items="items"
				:items-per-page="paginado.perPage"
				:item-class="row_classes"
				hide-default-footer
				class="elevation-1"
				mobile-breakpoint="0"
			>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn
						icon
						color="success"
						v-if="item.actions.edit"
						@click="$store.dispatch('Clientes/getResource', item.id)"
					>
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-btn
						icon
						color="error"
						v-if="item.actions.delete"
						@click="deshabilitar(item)"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
			<v-row v-if="!loader && items && items.length">
				<v-col class="mt-5">
					Registros Encontrados: {{ paginado.registros | numero }}
				</v-col>
				<v-col cols="12" md="8">
					<v-pagination
						class="mt-3"
						v-model="paginado.currentPage"
						:length="paginado.lastPage"
						total-visible="8"
					/>
				</v-col>
				<v-col></v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Clientes',
	computed: {
		...mapState({
			loader: state => state.Clientes.loader,
			headers: state => state.Clientes.headers,
			items: state => state.Clientes.collection,
			paginado: state => state.Clientes.paginado
		}),
		actual() {
			return this.paginado.currentPage;
		},
		search() {
			return this.paginado.search;
		}
	},
	mounted() {
		this.$store.dispatch('Clientes/getAll');
	},
	watch: {
		actual: function() {
			this.$store.dispatch('Clientes/getAll');
		},
		search: function(val) {
			if (!val) this.$store.dispatch('Clientes/getAll');
		}
	},
	methods: {
		deshabilitar(item) {
			let pregunta = `¿Deseas deshabilitar al cliente ${item.nombre}?`,
				text = 'No se podrán hacer presupuestos nuevos';
			window
				.swal({
					title: pregunta,
					text: text,
					icon: 'warning',
					buttons: ['Cancelar', 'Confirmar'],
					dangerMode: true
				})
				.then(confirmado => {
					if (confirmado) {
						this.$store.dispatch('Clientes/deleteResource', item.id);
					}
				});
		}
	}
};
</script>
