import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import router from '../router';
Vue.use(Vuex);

export default new Vuex.Store({
	modules,
	state: {
		appName: 'Zamgod Solutions',
		errors: []
	},
	mutations: {
		setErrors(state, payload = []) {
			state.errors = payload;
		}
	},
	actions: {
		reset({ commit }) {
			// resets state of all the modules
			Object.keys(modules).forEach(moduleName => {
				commit(`${moduleName}/setInitialState`);
			});
		},
		errores({ commit, dispatch }, e) {
			const status = e.response.status;
			switch (status) {
				case 403:
					window.swal('No cuentas con los permisos suficientes', {
						icon: 'error',
						timer: 1500
					});
					router.push({ name: 'Home' });
					dispatch('Auth/getMenu');
					break;

				case 404:
					window.swal('Ruta o recurso no encontrado', {
						icon: 'error',
						timer: 1500
					});
					break;

				case 401:
					window.swal('La sesion expiró', {
						icon: 'error',
						timer: 1500
					});
					dispatch('reset');
					localStorage.clear();
					window.axios.defaults.headers.common.Authorization =
						'Bearer token-falso';
					router.push('/').catch(() => {});
					break;

				case 422:
					commit('setErrors', e.response.data.errors);
					if (!e.response.data.errors) {
						window.swal(e.response.data.message, {
							icon: 'error',
							timer: 2000
						});
						router.push({ name: 'Home' });
					}
					break;

				case 418: {
					window.swal('Credenciales Inválidas', {
						icon: 'error',
						timer: 1500
					});
					break;
				}
				default:
					window.swal(e.response.data.exception, {
						icon: 'error',
						timer: 3500
					});
					break;
			}
		}
	},
	strict: false
});
