<template>
	<v-card height="100%">
		<v-form @submit.prevent="$store.dispatch('Clientes/sendResource')">
			<v-card-text v-if="cliente">
				<v-row>
					<v-col cols="12" md="6">
						<v-text-field
							outlined
							dense
							type="text"
							label="Nombre"
							v-model="cliente.nombre"
							:error-messages="errors.nombre"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<v-text-field
							outlined
							dense
							type="text"
							label="Representante"
							v-model="cliente.representante"
							:error-messages="errors.representante"
						/>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field
							outlined
							dense
							type="email"
							label="Correo"
							v-model="cliente.email"
							:error-messages="errors.email"
						/>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field
							outlined
							dense
							type="text"
							label="Teléfono"
							v-model="cliente.telefono"
							:error-messages="errors.telefono"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-card-actions>
					<v-btn color="primary" type="submit" :disabled="loader">
						{{ btnTitle }}
					</v-btn>
					<v-btn
						color="error"
						@click="$router.push({ name: 'clientes' })"
						:disabled="loader"
					>
						cancelar
					</v-btn>
				</v-card-actions>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Form',
	computed: {
		...mapState({
			errors: state => state.errors,
			cliente: state => state.Clientes.resource,
			loader: state => state.Clientes.loader
		}),
		btnTitle() {
			return this.cliente.id ? 'actualizar' : 'guardar';
		}
	},
	beforeMount() {
		this.$store.commit('setErrors');
		if (!this.cliente) {
			let id = this.$route.params.id;
			if (!id) {
				this.$store.dispatch('Clientes/nuevo');
			}
		}
	}
};
</script>
