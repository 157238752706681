/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueRouter from 'vue-router';
import authRoutes from './auth';
import Clientes from './clientes';
import Abilities from './abilities';
import Budgets from './budgets';

Vue.use(VueRouter);

const routes = [...authRoutes, ...Clientes, ...Abilities, ...Budgets];

const router = new VueRouter({
	routes,
	linkExactActiveClass: 'nav-item active',
	mode: 'history'
});

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);

	next();
});

export default router;
